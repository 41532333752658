import { createGlobalStyle, DefaultTheme } from 'styled-components'

import { dom } from '@fortawesome/fontawesome-svg-core'

export const theme: DefaultTheme = {
  colors: {
    gray: {
      white: 'white',
      off_white: 'hsl(0, 0%, 97%)',
      95: 'hsl(0, 0%, 95%)',
      light: 'hsl(0, 0%, 90%)',
      bright: 'hsl(0, 0%, 80%)',
      medium: 'hsl(0, 0%, 53%)',
      default: 'hsl(0, 0%, 33%)',
      dark: 'hsl(0, 0%, 13%)',
      black: 'black',
    },

    primary: {
      brand: `hsl(214, 46%, 37%)`, // #335889
      brand_disabled: `hsl(214, 0%, 37%)`, // #335889
      brand_tint: `hsl(214, 46%, 40%)`, // #335889
      brand_shade: `hsl(214, 46%, 34%)`, // #335889

      default: `hsl(214, 46%, 95%)`, // #ECF1F8
      default_disabled: `hsl(214, 0%, 95%)`, // #ECF1F8
      default_tint: `hsl(214, 46%, 98%)`, // #ECF1F8
      default_shade: `hsl(214, 46%, 92%)`, // #ECF1F8
      default_border: `hsl(214, 46%, 90%)`, // #DAE4F1

      icon: `hsl(214, 46%, 29%)`, // #28456C
      dark: `hsl(214, 46%, 16%)`, // #335889
      deep: `hsl(214, 46%, 8%)`, // #335889

      primary_dark: `hsl(214, 64%, 48%)`,
      default_dark: `hsl(214, 32%, 24%)`,
      default_dark_shade: `hsl(214, 32%, 18%)`,
      default_dark_border: `hsl(214, 32%, 16%)`,
    },

    danger: {
      solid: `hsl(360, 46%, 37%)`,
      solid_bright: `hsl(360, 46%, 50%)`,
      solid_disabled: `hsl(360, 0%, 37%)`,
      solid_tint: `hsl(360, 46%, 40%)`,
      solid_shade: `hsl(360, 46%, 34%)`,

      default: `hsl(360, 46%, 95%)`,
      default_disabled: `hsl(360, 0%, 95%)`,
      default_tint: `hsl(360, 46%, 98%)`,
      default_shade: `hsl(360, 46%, 92%)`,

      strong_bright: `hsl(360, 64%, 50%)`,
    },

    good: {
      default: `hsl(100, 46%, 37%)`,
    },
  },
}

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;

    box-sizing: border-box;
  }

  :root {
    color-scheme: light dark;
  }

  :focus {
    outline: auto .4rem highlight;
    outline: auto .4rem -webkit-focus-ring-color;
  }

  @media screen {
    html, body {
      min-height: 100%;
    
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-size: 16px;
    
      background-color: #f2f2f2;
    }

    @media (prefers-color-scheme: dark) {
      html, body {
        color: white;
        background-color: #000;
      }
    }
    
    body, input, .input, textarea, select, button {
      font-synthesis: none;
      font-feature-settings: 'kern';
      -moz-font-feature-settings: 'kern';
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font: inherit;
    }
  }

  @media print {
    :root {
      color-scheme: light;
    }

    html,
    body {
      font-family: 'Open Sans', sans-serif !important;
      font-size: 9pt !important;

      background-color: unset;
    }

    body {
      padding-top: 1px;

      line-height: 1.42857143;

      color: black;
    }
  }


  .container {
    width: calc(100% - 2rem);
    margin: 0 auto;

    @media screen and (min-width: 1032px) {
      max-width: 1000px;
    }
  }


  input:not([type="checkbox"]),
  .input,
  textarea,
  select,
  .btn {
    -webkit-appearance: none;
    appearance: none;

    padding: .5rem 1rem;

    font: inherit;

    background-color: white;

    border: 1px solid transparent;
    border-radius: .5rem;
  }

  input:not([type="checkbox"]),
  .input,
  textarea,
  select {
    width: 100%;

    padding: .75rem 1rem;

    color: #222;

    border-color: ${theme.colors.gray.bright};
    box-shadow: inset 0 2px 2px hsl(0 0% 0% / 0.15);
  }
  input:not([type="checkbox"]),
  .input,
  select {
    line-height: 1;
  }
  /* textarea {
    padding: .5rem;
  } */

  input:-ms-input-placeholder, input:-moz-placeholder,
  textarea:-ms-input-placeholder, textarea:-moz-placeholder {
    font-style: italic;
  }

  input:placeholder-shown,
  textarea:placeholder-shown {
    font-style: italic;
  }

  input::-moz-placeholder, input::-webkit-input-placeholder, input::placeholder,
  textarea::-moz-placeholder, textarea::-webkit-input-placeholder, textarea::placeholder {
    color: ${theme.colors.gray.medium};
  }

  input:focus, textarea:focus, select:focus, .input:focus, .input:focus-within {
    border-color: ${theme.colors.primary.brand};
    box-shadow: inset 0 1px 3px hsl(0 0% 0% / 0.1), inset 0 2px 6px hsl(0 0% 0% / 0.2);
  }

  input:disabled, textarea:disabled, select:disabled, .input:disabled {
    background-color: ${theme.colors.gray.light};
  }

  input:not(:placeholder-shown):not(:focus):invalid, input.error, .input.error {
    font-weight: 500;

    border-color: ${theme.colors.danger.strong_bright};
    box-shadow: inset 0 1px 3px hsl(0 0% 0% / 0.1), inset 0 2px 6px hsl(0 0% 0% / 0.2);
  }

  /* input:valid {

  } */

  /** Fixes iOS/iPadOS 15 date picker text alignment */
  input::-webkit-date-and-time-value {
    text-align: left;
  }

  input::-webkit-datetime-edit {
    padding: 0;
  }

  input::-webkit-datetime-edit-year-field, input::-webkit-datetime-edit-month-field, input::-webkit-datetime-edit-day-field, input::-webkit-datetime-edit-hour-field, input::-webkit-datetime-edit-minute-field, input::-webkit-datetime-edit-second-field, input::-webkit-datetime-edit-millisecond-field, input::-webkit-datetime-edit-meridiem-field {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media (prefers-color-scheme: dark) {
    .input, input[type='text'], input[type='email'], input[type='username'], input[type='password'], input[type='url'], input[type='datetime-local'], textarea, select {
      color: white;
      background-color: #222;
      border-color: #555;
    }

    input::-moz-placeholder, input::-webkit-input-placeholder, input::placeholder,
    textarea::-moz-placeholder, textarea::-webkit-input-placeholder, textarea::placeholder {
      color: #888;
    }

    .input:disabled, input:disabled, textarea:disabled, select:disabled {
      background-color: ${theme.colors.gray.default};
      border-color: transparent;
    }
  }

  .btn {
    display: inline-block;
    user-select: none;

    font-weight: 600;

    color: ${theme.colors.primary.brand};
    background-color: ${theme.colors.primary.default};
  }

  button.btn {
    text-decoration: none;
  }

  .btn:hover {
    background-color: ${theme.colors.primary.default_tint};
  }

  .btn:active {
    background-color: ${theme.colors.primary.default_shade};
  }

  .btn-big {
    padding: .75rem 1.5rem;

    border-radius: .75rem;
  }

  .btn-wide {
    width: 100%;
  }

  .btn.btn-primary {
    color: white;
    background-color: ${theme.colors.primary.brand};

    @media (prefers-color-scheme: dark) {
      color: white;
      background-color: ${theme.colors.primary.primary_dark};
    }
  }

  .btn.btn-primary:hover {
    background-color: ${theme.colors.primary.brand_tint};
  }

  .btn.btn-primary:active {
    background-color: ${theme.colors.primary.brand_shade};
  }

  .btn.btn-danger {
    color: ${theme.colors.danger.solid};
    background-color: ${theme.colors.danger.default};
  }

  .btn.btn-danger:hover {
    background-color: ${theme.colors.danger.default_tint};
  }

  .btn.btn-danger:active {
    background-color: ${theme.colors.danger.default_shade};
  }

  .btn.btn-danger_solid {
    color: white;
    background-color: ${theme.colors.danger.solid_bright};
  }

  .btn.btn-danger_solid:hover {
    background-color: ${theme.colors.danger.solid_tint};
  }

  .btn.btn-danger_solid:active {
    background-color: ${theme.colors.danger.solid_shade};
  }

  .btn.btn-default {
    color: ${theme.colors.primary.brand};
    background-color: ${theme.colors.primary.default};

    @media (prefers-color-scheme: dark) {
      color: ${theme.colors.primary.default};
      background-color: ${theme.colors.primary.default_dark};
    }

    &:hover {
      background-color: ${theme.colors.primary.default_shade};

      @media (prefers-color-scheme: dark) {
        background-color: ${theme.colors.primary.default_dark_shade};
      }
    }

    &:hover:active {
      background-color: ${theme.colors.primary.default_border};

      @media (prefers-color-scheme: dark) {
        background-color: ${theme.colors.primary.default_dark_border};
      }
    }
  }

  .btn[disabled], button[disabled] .btn {
    color: ${theme.colors.primary.brand_disabled};
    background-color: ${theme.colors.primary.default_disabled};
  }

  .btn.btn-primary[disabled], .btn.btn-danger[disabled] {
    color: ${theme.colors.primary.default_disabled};
    background-color: ${theme.colors.primary.brand_disabled};
  }

  .btn svg:not(:only-child) {
    margin-right: 1rem;
  }

  .btn svg,
  .btn svg {
    margin-left: -.25rem;
    margin-right: .75rem;
  }

  .btn.btn-icon svg {
    margin-right: -.25rem;
  }


  @media screen {
    label {
      font-weight: 700;
    }

    select {
      background-image: url('data:image/svg+xml;utf8,<svg width="24" height="24" fill="black" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z" /><path d="M0 0h24v24H0z" fill="none" /></svg>');
      background-repeat: no-repeat;
      background-position: calc(100% - 0.5rem) 50%;
    }

    @media (prefers-color-scheme: dark) {
      select {
        background-image: url('data:image/svg+xml;utf8,<svg width="24" height="24" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z" /><path d="M0 0h24v24H0z" fill="none" /></svg>');
      }
    }
  }



  /* Tour Guide component CSS */
  .react-tour-guide.react-tour-guide {
    --reactour-accent: rgb(52, 107, 179);
    padding: 3rem 1.5rem 1rem;
    
    line-height: 1.5;
    font-size: 1.125rem;
  }

  .react-tour-guide [data-tour-elem="badge"] {
    background-color: var(--reactour-accent);
  }

  .react-tour-guide [data-tour-elem="controls"] {
    justify-content: space-between;
  }

  .react-tour-guide h1 {
    margin: 0 0 2rem;

    font-size: 1.5rem;
    text-align: center;
    line-height: 1.125;
  }

  .react-tour-guide p + p {
    margin-top: 1rem;
  }

  @media only screen and (prefers-color-scheme: dark) {
    .react-tour-guide.react-tour-guide {
      color: white;
      background-color: #333;
    }

    .react-tour-guide button {
      color: white;
    }
  }



  /* Font Awesome */
  ${dom.css()}
`
