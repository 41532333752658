import { useState, useEffect, useContext, createContext } from 'react'
import type { ReactNode } from 'react'
import Banner from '../components/Banner'
import type { IMessage } from '../declarations/interfaces.d'

import { useQuery } from 'react-query'
import type { AxiosError } from 'axios'
import toast from 'react-hot-toast'
import { manadsbladetAPI } from '../utils/API'

interface MessageProps {
  message: IMessage
  onDismiss(): void
}
export function Message({ message, onDismiss }: MessageProps) {
  return (
    <Banner
      key={message.text}
      icon='info-circle'
      iconType='fad'
      dismissText='Okej'
      onDismissClick={onDismiss}
    >
      <p>{message.text}</p>
    </Banner>
  )
}

interface IMessagesContext {
  messages: IMessage[]
  MessagesToRender: ReactNode
}

const MessagesContext = createContext<IMessagesContext>({
  messages: [],
  MessagesToRender: null,
})

export function MessagesProvider({ children }: { children: ReactNode }) {
  const [messages, setMessages] = useState<IMessage[]>([])

  const { data, error } = useQuery<IMessage[], AxiosError>(
    ['messages'],
    manadsbladetAPI.getMessages,
    {
      staleTime: 1000 * 60 * 30, // 30 minutes
    },
  )

  useEffect(() => {
    if (data?.length > 0) {
      setMessages(data)
      // setMessages(data.map(msg => ({
      //   ...msg,
      //   id: uuid4() // TODO: Maybe this should get set on the server, so we can keep the integrity and disiss it forever.
      // })))
    }
  }, [data])

  useEffect(() => {
    if (error) {
      console.error('Failed to fetch messages', error)
      toast.error('Lyckades inte hämta meddelanden.')
    }
  }, [error])

  function dismissMessage(id: number) {
    const newMessages = messages.filter(msg => msg.id !== id)

    // TODO: Store the dismissed message id in localStorage, to prevent it from showing up on the next page load

    setMessages(newMessages)
  }

  const MessagesToRender =
    messages.length > 0
      ? messages.map(message => (
          <Message
            key={message.id + '-' + message.text}
            message={message}
            onDismiss={() => {
              dismissMessage(message.id)
            }}
          />
        ))
      : null

  return (
    <MessagesContext.Provider value={{ messages, MessagesToRender }}>
      {children}
    </MessagesContext.Provider>
  )
}

export function useMessages() {
  return useContext(MessagesContext)
}
