import {
  // useState,
  useEffect,
  useContext,
  createContext,
  ReactNode,
} from 'react'

import { setUser as setSentryUser, getCurrentScope } from '@sentry/nextjs'

import Router, { useRouter } from 'next/router'

// import { suspend } from 'suspend-react'

import type { Session, SupabaseClient } from '@supabase/supabase-js'
import type { IUser } from '../declarations/interfaces'
import { useMachine } from '@xstate/react'
import { authMachine, AuthMachineSend, AuthMachineState } from './auth.machine'
// import isEqual from 'lodash.isequal'
import {
  useSession,
  useSupabaseClient,
  useUser,
} from '@supabase/auth-helpers-react'

interface IAuthContext {
  state: AuthMachineState | null
  send: AuthMachineSend | null

  session: Session | undefined
  user: Pick<IUser, 'email' | 'app_metadata'> | null | undefined
  customClaims: IUser['app_metadata'] | null
  token: Session['access_token'] | undefined | null
  cookieUpdatedAt: number // Date

  supabaseClient: SupabaseClient | undefined
}

const AuthContext = createContext<IAuthContext>({
  state: null,
  send: null,

  session: undefined,
  user: undefined,
  customClaims: null,
  token: undefined,
  cookieUpdatedAt: 0,

  supabaseClient: undefined,
})

//#region Suspense
// async function getInitialAuthState (): Promise<{ user: IUser, customClaims: IUser['app_metadata'], token: Session['access_token'] }> {
//   return new Promise((resolve, reject) => {
//     supabase.auth.onAuthStateChange((event, newSession) => {
//       if (event === 'TOKEN_REFRESHED') {
//         if (newSession.user) {
//           resolve({ user: newSession.user, customClaims: newSession.user.app_metadata, token: newSession.access_token })
//         }
//       }
//     }).then(res => { res.data.unsubscribe() })
//   })
// }
//#endregion Suspense

// type UpdateTokenFetchParams = {
//   queryKey: [string, string | null, string]
// }
// async function updateTokenFetch({ queryKey }: UpdateTokenFetchParams) {
// async function updateTokenFetch(token: string, email: string) {
//   console.log('updateTokenFetch args:', { token, email })
//   // const [, token, email] = queryKey

//   if (token && token.startsWith('ey')) {
//     await fetch('/api/token', {
//       method: 'post',
//       headers: {
//         'Content-Type': 'application/json; charset=UTF-8',
//       },
//       body: JSON.stringify({
//         token,
//       }),
//     })
//       .then(response => {
//         if (!response.ok) {
//           throw new Error(
//             `Error ${response.status} when fetching POST /api/token: ${response.statusText}`,
//           )
//         }

//         // toast('Inloggad som ' + email)
//       })
//       .catch(err => {
//         toast.error(JSON.stringify(err))
//         throw err
//       })
//   } else {
//     await fetch('/api/token', {
//       method: 'delete',
//     })
//       .then(response => {
//         if (!response.ok) {
//           throw new Error(
//             `Error ${response.status} when fetching DELETE /api/token: ${response.statusText}`,
//           )
//         }
//         toast('Utloggad')
//       })
//       .catch(err => {
//         toast.error(JSON.stringify(err))
//         throw err
//       })
//   }
// }

export function AuthProvider({ children }: { children: ReactNode }) {
  const supabase = useSupabaseClient()

  //#region Suspense
  // const { user, customClaims, token } = suspend(getInitialAuthState, ['initialAuthState'])

  // if (!user) {
  //   nookies.destroy(null, 'token')
  // }

  // nookies.destroy(null, 'token')

  // nookies.set(null, 'token', token, { path: '/' })
  //#endregion Suspense

  const [state, send] = useMachine(authMachine)

  // const [session, setSession] = useState<Session>(undefined)

  const user = useUser()
  const session = useSession()
  // const [user, setUser] = useState<
  //   Pick<IUser, 'app_metadata' | 'email'> | null | undefined
  // >(undefined)
  // // const [customClaims, setCustomClaims] = useState<ICustomClaims | null>(null)
  // const [customClaims, setCustomClaims] = useState<
  //   IUser['app_metadata'] | null
  // >(undefined)
  // const [token, setToken] = useState<string | null | undefined>(undefined)

  // Listen for token changes.
  // Call setUser and write new token as a cookie
  useEffect(() => {
    // function updateSessionState(session: Session) {
    if (session && user) {
      const { app_metadata, email } = user

      //     // setSession(session)
      //     // setUser({
      //     //   app_metadata,
      //     //   email,
      //     // })
      //     // setToken(session.access_token)
      //     // setCustomClaims(session.user.app_metadata as IUser['app_metadata'])

      if (state.value === 'idle') {
        console.log(
          'idle state, has_user',
          state.value,
          session,
          app_metadata,
          email,
        )
        send('HAS_USER', {
          data: {
            session,
            user: {
              app_metadata,
              email,
            },
          },
        })
      } else if (state.value === 'unauthenticated') {
        console.log('unauthenticated state, has_user', state.value)
        send('SIGN_IN', {
          data: {
            session,
            user: {
              app_metadata,
              email,
            },
          },
        })
      } else if (
        state.value === 'cookie_ok' &&
        session.access_token !== state.context.token
      ) {
        console.log('cookie_ok state, update_session', session)
        send('UPDATE_SESSION', {
          data: {
            session,
            user: {
              app_metadata,
              email,
            },
          },
        })
      }

      setSentryUser({ email: user.email })
    } else {
      //     // setSession(null)
      //     // setUser(null)
      //     // setToken(null)
      //     // setCustomClaims(null)

      if (state.value === 'idle') {
        console.log('idle state, no session', session)
        send('NO_USER')
      }

      const scope = getCurrentScope()
      scope.setUser(null)
      //   }
    }

    // The only reason to use onAuthStateChanged is because reauthenticateWithCredential refreshes the token when verifying the password
    // When using onAuthStateChanged, it only fires when we sign in, once per hard refresh page.
    // ? Maybe use onIdTokenChanged instead, to make sure the token is always fresh?
    // supabase.auth.getSession().then(({ data: { session: _session } }) => {
    //   if (isEqual(session, _session) === false) {
    //     console.log('In auth ctx useEffect, updating first session state:', {
    //       old: session,
    //       new: _session,
    //       isEqual: isEqual(session, _session),
    //     })
    //     updateSessionState(_session)
    //   }
    // })

    // const {
    //   data: { subscription },
    // } = supabase.auth.onAuthStateChange((_event, newSession) => {
    //   if (_event === 'SIGNED_OUT') {
    //     updateSessionState(null)
    //   } else if (isEqual(session, newSession) === false) {
    //     console.log(
    //       'Supabase onAuthStateChanged, and session did change:',
    //       _event,
    //       {
    //         old: session,
    //         new: newSession,
    //       },
    //     )

    //     updateSessionState(newSession)
    //   }
    // })

    // return () => {
    //   subscription.unsubscribe()
    // }
    // }, [state.value, state.context.token, send, supabase.auth])
  }, [user, session, send, state.context, state.value])

  // Force refresh the token every 30 minutes
  // useEffect(() => {
  //   const handle = setInterval(async () => {
  //     const user = auth.currentUser

  //     if (user) {
  //       const token = await user.getIdToken(true)

  //       // confirm('Sidan kommer att uppdateras. Vill du spara dina ändringar först?')

  //       if (!token) {
  //         setToken(null)
  //         // ? setUser(null)
  //         // ? configureSentryScope(scope => scope.setUser(null))

  //         return
  //       }

  //       setToken(token)
  //     }
  //   }, 30 * 60 * 1000) // The token expires by default after 60 minutes

  //   return () => clearInterval(handle)
  // },
  // // TODO: Don't use the `token` as an argument for choosing what to render. Make it refresh in the background without unmounting everything. At least prompt the user before refreshing it? Especially important on the /upload routes.
  // // Clear the timer whenever we navigate to a new page. This is to ensure that the page doesn't re-render after a fixed amount of time and lose data in the DOM.
  // [router]
  // )

  return (
    <AuthContext.Provider
      value={{
        state,
        send,

        // session: state.context.session,
        session: session,
        // user: state.context.user,
        user: user
          ? { email: user.email, app_metadata: user.app_metadata }
          : null,
        // customClaims: state.context.customClaims,
        customClaims: user?.app_metadata,
        token: state.context.token,
        // token: session?.access_token,
        // cookieUpdatedAt: dataUpdatedAt,
        cookieUpdatedAt: state.context.cookieUpdatedAt,

        supabaseClient: supabase,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}

export function useAuthGuard(
  destinationPath?: string /* , allowedRole?: IRole */,
) {
  const {
    session,
    user,
    customClaims,
    token,
    cookieUpdatedAt,
    state,
    send,
    supabaseClient,
  } = useAuth()

  const router = useRouter()

  useEffect(() => {
    // console.log('Auth guard', {
    //   session,
    //   user,
    //   pathname: Router.pathname,
    //   destinationPath,
    //   cookieUpdatedAt,
    // })

    if (state.value === 'cookie_ok') {
      // User not logged in. (undefined while pending) Also not already on /login page (prevent infinite redirect)
      if (user === null && Router.pathname.startsWith('/login') === false) {
        const logoutRoute = Router.pathname.startsWith('/logout')

        console.log(
          'Should redirect to /login with destination ' +
            (destinationPath ?? Router.pathname),
        )

        Router.replace({
          pathname: '/login',
          // search: new URLSearchParams({ next: destinationPath ?? Router.pathname }).toString()
          query: logoutRoute
            ? undefined
            : { next: destinationPath ?? Router.pathname },
        })
      } else if (destinationPath && user !== null && typeof user === 'object') {
        console.log(
          'Should redirect to ' + destinationPath + ' from ' + router.pathname,
        )

        // Doing a client-side navigation doesn't work. It doesn't seem to pass the token cookie along, and needs to do a proper page navigation in order for the middleware to receive the cookie!
        // location.assign(destinationPath)
        Router.replace(destinationPath)
      }
    } else {
      console.log('Token cookie not updated yet. Will not redirect yet.')
    }
  }, [user, cookieUpdatedAt, session, destinationPath, router, state.value])

  return {
    state,
    send,

    session,
    user,
    customClaims,
    token,
    cookieUpdatedAt,

    supabaseClient,
  }
}
