import styled from 'styled-components'

export const StyledBanner = styled.section`
  min-height: 5.5rem;
  padding: 1.5rem 0.5rem 0.5rem 1rem;

  background-color: white;

  &.inline {
    border-radius: 0.25rem;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;

    width: 100%;
  }

  @media (prefers-color-scheme: dark) {
    color: white;
    background-color: #1c1c1c;
  }

  @supports (padding: env(safe-area-inset-left)) {
    padding-left: calc(1rem + env(safe-area-inset-left));
    padding-right: calc(1rem + env(safe-area-inset-right));
  }

  .messageContainer {
    display: flex;
    flex-grow: 1;

    svg {
      flex-basis: 2.5rem;
      margin-right: 1rem;

      font-size: 2.5rem;

      color: #28456c;

      border-radius: 1.25rem;

      @media (prefers-color-scheme: dark) {
        color: #4272b3;
      }
    }

    .paragraphs {
      flex: 1;

      margin-bottom: 0.5rem;

      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 0.5em;
      }

      p {
        align-self: center;

        width: calc(100% - 3.5rem);

        margin-bottom: 1rem;

        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;

    width: 100%;
    height: 2.25rem;

    button {
      display: flex;
      align-items: center;

      height: 2.25rem;

      padding: 0 0.5rem;

      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      text-decoration: underline;

      color: #28456c;
      background-color: transparent;
      border: 0;

      & + & {
        margin-left: 0.5rem;
      }

      @media (prefers-color-scheme: dark) {
        color: white;
      }
    }

    button + button {
      margin-left: 0.5rem;
    }
  }

  /* Variants */
  &.warning {
    font-weight: 500;
    background-color: hsl(46.63, 100%, 88%);

    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.05);

    @media (prefers-color-scheme: dark) {
      background-color: hsl(46.63, 64%, 24%);
    }

    svg {
      color: hsl(46.63, 90%, 40%);

      @media (prefers-color-scheme: dark) {
        color: hsl(46.63, 64%, 56%);
      }
    }
  }
`
