import axios, { AxiosError } from 'axios'

import { toast } from 'react-hot-toast'

import type {
  IMessageInsert,
  IMessageRow,
} from '../declarations/supabase-interfaces.d'

type IMessageResponse = Omit<IMessageRow, 'created_at' | 'congregation_uuid'>

export const apiAxios = axios.create({
  // baseURL: process.env.NEXT_PUBLIC_URL,
  baseURL: process.env.NEXT_PUBLIC_REMIX_URL,
  // baseURL: 'http://localhost:5001/api',
  responseType: 'json',
})

export const manadsbladetAPI = {
  addAuthTokenToHeaders(authToken: string) {
    if (!authToken) {
      console.error('Auth token not provided')
      return
    }

    if (typeof authToken !== 'string') {
      console.error('Auth token not a string')
      return
    }

    apiAxios.defaults.headers.common.Authorization = 'Bearer ' + authToken
  },

  async getMessages(): Promise<IMessageResponse[]> {
    const response = await apiAxios.get<IMessageResponse[]>('/messages')

    if (response.data) {
      return response.data
    }

    return []
  },

  async createMessage(
    message: Pick<IMessageInsert, 'text' | 'expires_at'>,
  ): Promise<IMessageResponse[]> {
    const response = await apiAxios.post<IMessageResponse[]>(
      '/messages',
      message,
    )

    if (response.data) {
      return response.data
    }

    return []
  },

  async sendBugReport({
    title,
    description,
  }: {
    title: string
    description: string
  }) {
    try {
      const response = await apiAxios.post<true>('bug-report', {
        title,
        description,
      })

      return response.status === 200 && response.data === true
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          console.error(
            error.response.data,
            error.response.status,
            error.response.headers,
          )
        } else if (error.request) {
          console.error(error.request)
        }

        toast.error(
          'Hoppsan! Det gick inte att skicka in felrapporten. Verkar som att något gick fel! Försök igen om en stund.',
        )

        console.error(error.config)
      } else {
        console.error(
          'Error in send bug report',
          (error as Error).message ?? (error as Error).name,
        )
      }
    }
  },
}

export default manadsbladetAPI
