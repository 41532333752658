
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { AppProps } from 'next/app'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from 'styled-components'
import { Toaster } from 'react-hot-toast'

import '../config/icons'
import { AuthProvider } from '../contexts/auth'
import { MessagesProvider } from '../contexts/messages'
import Head from 'next/head'

import { GlobalStyle, theme } from '../config/GlobalStyle'
import { OverlayProvider } from '@react-aria/overlays'
import { createPagesBrowserClient } from '@supabase/auth-helpers-nextjs'
import { SessionContextProvider, Session } from '@supabase/auth-helpers-react'
import { useState } from 'react'

import Router from 'next/router'
import NProgress from 'nprogress'

if (process.env.NODE_ENV === 'development') {
  require('../mocks')
}

// import { Suspense } from 'react'
// import { ErrorBoundary } from 'react-error-boundary'

// if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
//   import('react').then(React => {
//     import('react-dom').then(ReactDOM => {
//       import('@axe-core/react').then(axe => {
//         axe.default(React, ReactDOM, 1000, {})
//       })
//     })
//   })
// }

NProgress.configure({
  showSpinner: false,
})
Router.events.on('routeChangeStart', () => {
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => {
  NProgress.done(false)
})
Router.events.on('routeChangeError', () => {
  NProgress.done(false)
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      staleTime: 1000 * 60 * 5,
    },
  },
})

function ManadsbladetNextApp({
  Component,
  pageProps,
}: AppProps<{
  initialSession: Session
}>) {
  // Create a new supabase browser client on every first render.
  const [supabaseClient] = useState(() => createPagesBrowserClient())

  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover'
        />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <title>M&aring;nadsbladet</title>
      </Head>
      <GlobalStyle />
      {/* <ErrorBoundary fallback={<h1>HOPPSAN!!</h1>}> */}
      {/* <Suspense fallback={<h1>LADDAR!!</h1>}> */}
      <QueryClientProvider client={queryClient}>
        <SessionContextProvider
          supabaseClient={supabaseClient}
          initialSession={pageProps.initialSession}
        >
          <AuthProvider>
            <MessagesProvider>
              <ThemeProvider theme={theme}>
                <OverlayProvider>
                  <Toaster />
                  <Component {...pageProps} />
                </OverlayProvider>
              </ThemeProvider>
            </MessagesProvider>
          </AuthProvider>
        </SessionContextProvider>
      </QueryClientProvider>
      {/* </Suspense> */}
      {/* </ErrorBoundary> */}
    </>
  )
}

const __Page_Next_Translate__ = ManadsbladetNextApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  