import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconPrefix } from '@fortawesome/pro-light-svg-icons'
import { ComponentProps, HTMLProps, ReactNode } from 'react'
import classnames from 'class-names'

import { StyledBanner } from './styles'

type BannerProps = {
  dismissText?: string
  onDismissClick?(): void
  performText?: string
  onPerformClick?(): void
  icon: IconName
  iconType: IconPrefix

  inline?: boolean

  children?: ReactNode
} & HTMLProps<HTMLElement> &
  ComponentProps<typeof StyledBanner>

export function Banner({
  children,
  dismissText,
  onDismissClick,
  performText,
  onPerformClick,
  icon,
  iconType = 'far',
  inline = false,
  className,
  ...props
}: BannerProps) {
  return (
    <StyledBanner
      className={classnames(inline && 'inline', className)}
      {...props}
    >
      <div className={classnames('wrapper', !inline && 'container')}>
        <div className='messageContainer'>
          {icon ? <FontAwesomeIcon icon={[iconType, icon]} /> : null}
          <div className='paragraphs'>{children}</div>
        </div>
        {dismissText || performText ? (
          <footer>
            <button type='button' onClick={onDismissClick}>
              {dismissText}
            </button>
            <button type='button' onClick={onPerformClick}>
              {performText}
            </button>
          </footer>
        ) : null}
      </div>
    </StyledBanner>
  )
}

export default Banner
