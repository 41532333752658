import {
  assign,
  BaseActionObject,
  createMachine,
  Event,
  EventData,
  ResolveTypegenMeta,
  SCXML,
  ServiceMap,
  SingleOrArray,
  State,
} from 'xstate'

import type { Session } from '@supabase/supabase-js'
import type { IUser } from '../declarations/interfaces'
import { Typegen0 } from './auth.machine.typegen'
import { apiAxios, manadsbladetAPI } from '../utils/API'

export type AuthMachineState = State<
  IAuthMachineContext,
  AuthMachineEvent,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
    context: IAuthMachineContext
  },
  ResolveTypegenMeta<Typegen0, AuthMachineEvent, BaseActionObject, ServiceMap>
>
export type AuthMachineSend = (
  event: SCXML.Event<AuthMachineEvent> | SingleOrArray<Event<AuthMachineEvent>>,
  payload?: EventData,
) => AuthMachineState

export interface IAuthMachineContext {
  session: Session | null | undefined
  user: Pick<IUser, 'email' | 'app_metadata'> | null | undefined
  customClaims: IUser['app_metadata'] | null | undefined
  token: Session['access_token'] | null | undefined
  cookieUpdatedAt: number | null
}
export type AuthMachineEvent =
  | {
      type: 'HAS_USER'
      data: {
        session: Session
        user: Pick<IUser, 'email' | 'app_metadata'>
      }
    }
  | { type: 'NO_USER' }
  | {
      type: 'SIGN_IN'
      data: {
        session: Session
        user: Pick<IUser, 'email' | 'app_metadata'>
        secret?: string
      }
    }
  | { type: 'COOKIE_SET'; data: { cookieUpdatedAt: number } }
  | {
      type: 'UPDATE_SESSION'
      data: {
        session: Session
        user: Pick<IUser, 'email' | 'app_metadata'>
      }
    }
  | {
      type: 'SIGN_OUT'
      data: { session: null; user: null; customClaims: null; token: null }
    }
  | { type: 'SIGNED_OUT' }
  | { type: 'COOKIE_CLEAR' }

export const authMachine = createMachine(
  {
    tsTypes: {} as import('./auth.machine.typegen').Typegen0,
    preserveActionOrder: true,
    id: 'auth',
    schema: {
      context: {} as IAuthMachineContext,
      events: {} as AuthMachineEvent,
    },
    context: {
      session: undefined,
      user: undefined,
      customClaims: undefined,
      token: undefined,
      cookieUpdatedAt: null,
    },
    initial: 'idle',
    states: {
      idle: {
        on: {
          NO_USER: 'unauthenticated',
          HAS_USER: {
            target: 'signed_in',
            actions: 'setUser',
          },
        },
      },
      unauthenticated: {
        // entry: ['destroyCookie', 'clearContext'],
        entry: 'clearContext',
        on: {
          SIGN_IN: {
            target: 'signed_in',
            actions: 'setUser',
          },
        },
      },
      signed_in: {
        invoke: {
          id: 'setCookie',
          src: (context, event) => {
            console.log('signed_in event:', event)
            // console.log('Set cookie token via machine', context.token)
            return fetch('/api/token', {
              method: 'post',
              headers: {
                'Content-Type': 'application/json; charset=UTF-8',
              },
              body: JSON.stringify({
                token: context.token,
              }),
            }).then(async res => {
              if (!res.ok) {
                throw new Error(
                  `Error ${res.status} when fetching POST /api/token: ${res.statusText}`,
                )
              }

              if (context.token) {
                manadsbladetAPI.addAuthTokenToHeaders(context.token)
              }

              if (typeof event['secret'] !== 'undefined') {
                await apiAxios.post(
                  '/token',
                  {
                    secret: event['secret'],
                  },
                  {
                    withCredentials: true,
                  },
                )
              }
            })
          },
          onDone: {
            target: 'cookie_ok',
            actions: assign({ cookieUpdatedAt: Date.now() }),
          },
          onError: {
            target: 'error',
            // actions: assign({}),
          },
        },
      },
      cookie_ok: {
        on: {
          UPDATE_SESSION: {
            target: 'signed_in',
            actions: 'setUser',
          },
          SIGN_OUT: {
            target: 'signed_out',
            actions: 'clearUser',
          },
        },
      },
      signed_out: {
        invoke: {
          id: 'destroyCookie',
          src: () => {
            console.log('Delete cookie token via machine')
            return fetch('/api/token', {
              method: 'delete',
            }).then(async res => {
              if (!res.ok) {
                throw new Error(
                  `Error ${res.status} when fetching DELETE /api/token: ${res.statusText}`,
                )
              }

              await apiAxios.post(
                '/token',
                {
                  secret: null,
                },
                {
                  withCredentials: true,
                },
              )

              delete apiAxios.defaults.headers.common['Authorization']
            })
          },
          onDone: {
            target: 'unauthenticated',
            actions: assign({ cookieUpdatedAt: () => null }),
          },
          onError: {
            target: 'error',
            // actions: assign({}),
          },
        },
      },
      error: {
        type: 'final',
      },
    },
  },
  {
    actions: {
      clearContext: assign({
        session: () => null,
        user: () => null,
        customClaims: () => null,
        token: () => null,
        cookieUpdatedAt: () => null,
      }),
      setUser: assign({
        session: (_context, event) => event.data.session,
        user: (_context, event) => event.data.user,
        customClaims: (_context, event) => event.data.user.app_metadata,
        token: (_context, event) => event.data.session.access_token,
      }),
      clearUser: assign({
        session: () => null,
        user: () => null,
        customClaims: () => null,
        token: () => null,
      }),
    },
  },
)
