import { library, config } from '@fortawesome/fontawesome-svg-core'

import {
  faBars as faBarsRegular,
  faArrowToLeft as faArrowToLeftRegular,
  faArrowToRight as faArrowToRightRegular,
  faArrowLeft as faArrowLeftRegular,
  faArrowRight as faArrowRightRegular,
  faSpinnerThird as faSpinnerThirdRegular,
  faFilePdf as faFilePdfRegular,
  faSignIn as faSignInRegular,
  faAngleLeft as faAngleLeftRegular,
  faAngleRight as faAngleRightRegular,
  faFileImport as faFileImportRegular,
  faPencilAlt as faPencilAltRegular,
  faTrashAlt as faTrashAltRegular,
  faPlus as faPlusRegular,
  faSpinner as faSpinnerRegular,
} from '@fortawesome/pro-regular-svg-icons'

// Menu icons
import {
  faMap as faMapLight,
  faColumns as faColumnsLight,
  faKeynote as faKeynoteLight,
  faBriefcase as faBriefcaseLight,
  faStickyNote as faStickyNoteLight,
  faUserFriends as faUserFriendsLight,
  faLink as faLinkLight,
  faCommentLines as faCommentLinesLight,
  faUserCircle as faUserCircleLight,
  faDoorOpen as faDoorOpenLight,
  faSignIn as faSignInLight,
  faSignOut as faSignOutLight,
  faTools as faToolsLight,
  faPrint as faPrintLight,
  faQuestionCircle as faQuestionCircleLight,
  faCommentAltExclamation as faCommentAltExclamationLight,
  faExclamationCircle as faExclamationCircleLight,
  faChalkboardTeacher as faChalkboardTeacherLight,
  faAddressCard as faAddressCardLight,
  faInfoCircle as faInfoCircleLight,
  faFilePdf as faFilePdfLight,
} from '@fortawesome/pro-light-svg-icons'
import {
  faMap as faMapSolid,
  faColumns as faColumnsSolid,
  faKeynote as faKeynoteSolid,
  faBriefcase as faBriefcaseSolid,
  faStickyNote as faStickyNoteSolid,
  faUserFriends as faUserFriendsSolid,
  faLink as faLinkSolid,
  faCommentLines as faCommentLinesSolid,
  faUserCircle as faUserCircleSolid,
  faDoorOpen as faDoorOpenSolid,
  faSignIn as faSignInSolid,
  faSignOut as faSignOutSolid,
  faTools as faToolsSolid,
  faPrint as faPrintSolid,
  faQuestionCircle as faQuestionCircleSolid,
  faCommentAltExclamation as faCommentAltExclamationSolid,
  faLockAlt as faLockAltSolid,
  faLockOpenAlt as faLockOpenAltSolid,
  faGripVertical as faGripVerticalSolid,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faHome as faHomeSolid,
  faTrashAlt as faTrashAltSolid,
  faPencilAlt as faPencilAltSolid,
  faEye as faEyeSolid,
  faEyeSlash as faEyeSlashSolid,
  faChevronDown as faChevronDownSolid,
  faUserShield as faUserShieldSolid,
  faUsersCog as faUsersCogSolid,
  faUser as faUserSolid,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faCompass as faCompassDuotone,
  faInfoCircle as faInfoCircleDuotone,
} from '@fortawesome/pro-duotone-svg-icons'

// Tell Font Awesome to skip adding the CSS automatically since it's being imported above
config.autoAddCss = false

library.add(
  faBarsRegular,
  faArrowToLeftRegular,
  faArrowToRightRegular,
  faArrowLeftRegular,
  faArrowRightRegular,
  faSpinnerThirdRegular,
  faFilePdfRegular,
  faSignInRegular,
  faAngleLeftRegular,
  faAngleRightRegular,
  faFileImportRegular,
  faPencilAltRegular,
  faTrashAltRegular,
  faMapLight,
  faColumnsLight,
  faKeynoteLight,
  faBriefcaseLight,
  faStickyNoteLight,
  faUserFriendsLight,
  faLinkLight,
  faCommentLinesLight,
  faUserCircleLight,
  faDoorOpenLight,
  faSignInLight,
  faSignOutLight,
  faToolsLight,
  faPrintLight,
  faQuestionCircleLight,
  faCommentAltExclamationLight,
  faMapSolid,
  faColumnsSolid,
  faKeynoteSolid,
  faBriefcaseSolid,
  faStickyNoteSolid,
  faUserFriendsSolid,
  faLinkSolid,
  faCommentLinesSolid,
  faUserCircleSolid,
  faDoorOpenSolid,
  faSignInSolid,
  faSignOutSolid,
  faToolsSolid,
  faPrintSolid,
  faQuestionCircleSolid,
  faCommentAltExclamationSolid,
  faHomeSolid,
  faTrashAltSolid,
  faPencilAltSolid,
  faCompassDuotone,
  faInfoCircleDuotone,
  faPlusRegular,
  faLockAltSolid,
  faLockOpenAltSolid,
  faGripVerticalSolid,
  faExclamationCircleLight,
  faEyeSolid,
  faEyeSlashSolid,
  faSpinnerRegular,
  faChalkboardTeacherLight,
  faAddressCardLight,
  faChevronDownSolid,
  faUserShieldSolid,
  faUsersCogSolid,
  faUserSolid,
  faInfoCircleLight,
  faFilePdfLight,
)
